import React, {useLayoutEffect, useMemo, useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import Layout from "./Layout"
import * as styles from "../styles/hub.module.scss"
import DuotoneImg from "../components/DuotoneImg.js"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import BreakingNews from "./BreakingNews"
import TagsMenu from "./TagsMenu"
import Doodle from "./Doodle.js"
import { useWindowSize } from "../hooks/useWindowSize"
import HubInlineVideo from "./HubInlineVideo"

const Hub = ({location, page, slides, hub, tags}) => {
  
  const repeat = (arr, n) => Array.from({ length: arr.length * n }, (_, i) => arr[i % arr.length])

  const orderedSlices = useMemo( () => {
    return slides.length < 8 ? repeat(slides, 7)
    : slides
  }, [slides]) 

  const fromPage = typeof window === `undefined` ? false : (sessionStorage.getItem("previous_hub") === hub ? true : false)
  
  const windowSize = useWindowSize()
  
  const slidesLength = orderedSlices.length

  // const [count, setCount] = useState(function(){
  //   return fromPage ? location.state.count
  //   : slidesLength - 4
  // });

  const [count, setCount] = useState(function(){
    return fromPage ? +(sessionStorage.getItem("count"))
    : slidesLength - 4
  });

 

  const counter = (slidePos) => {
    let newCount
    if (slidePos < 4){
      newCount = count-1
    } else if(slidePos > 4) {
      newCount = count+1
    } else return
    if (newCount === -1) newCount = slidesLength
    setCount(newCount)
  }

  // Swipe hub

  const isTouchFn = () => {
    try {
      document.createEvent("TouchEvent");
      return true
    } catch (e){
      return false;
    }
  }

  const isTouch = isTouchFn()
  
  const [curSwipe, setCurSwipe] = useState(null)
  const [isDragging, setIsDragging] = useState(false)
  const [startPos, setStartPos] = useState(0)
  const [pos, setPos] = useState(0)

  const handleSlideTouchStart = (e, slidePos) => {
    if (windowSize.desktop || slidePos !== 4 ) return
      setPos(0)
       setStartPos(isTouch ? e.touches[0].pageY : e.pageY)
       setIsDragging(true)
       setCurSwipe(slidePos)   
  }

  const handleSlideTouchMove = (e, slidePos) => {
    if (!isDragging || slidePos !== 4) return
    const newPos = isTouch ? e.touches[0].pageY - startPos : e.pageY - startPos
    setPos(newPos)
  }

  const handleSlideTouchEnd = (e) => {
    if (!isDragging) return
   
    const delta = pos
    const min = 0.1 * windowSize.width

    let newCount
    if (delta > min ){
      newCount = count - 1
    } 
    else if (delta < -1 * min ){
      newCount = count + 1
    } else {
      newCount = count
    }
    if (newCount === -1) newCount = slidesLength
    setCount(newCount)
    setCurSwipe(null)
    setIsDragging(false)
  }

  const [opening, setOpening] = useState(false)
  const [closing, setClosing] = useState(function(){
    return fromPage ? true
      : false
  })

  useLayoutEffect(() => {
    if (fromPage){
      setClosing(false)
    }
  },[closing])

  const handleUserKeyPress = ({keyCode}) => {
    let newCount
    if (keyCode === 37 ){
      newCount = count-1
    } else if(keyCode === 39 ) {
      newCount = count+1
    } else return
    if (newCount === -1) newCount = slidesLength
    setCount(newCount)
  }

  useEffect(() => {
      window.addEventListener("keydown", handleUserKeyPress);
      return () => {
          window.removeEventListener("keydown", handleUserKeyPress);
      };
  }, [handleUserKeyPress]);
    
  const [inlinePlay, setInlinePlay] = useState(false)

  return (
    <Layout page={page} type="hub">
      
      { tags && 
      <TagsMenu data={tags} lang={page.lang.slice(0,2)} moving={opening || closing ? true : false}/>
      }

      <div className={styles.hub}>
        {orderedSlices.map( (slide, index) => {
              const slidePos = [...Array(8).keys()].find((e)=>(count+e)%slidesLength === index) ?? "hided"
              const lang = slide.lang.slice(0, 2)
              const parentPermalink = slide.type === "creation" ? "creations" : "news"
              const permalink = slide.type === "page" ? `/${lang}/${slide.uid}` : `/${lang}/${parentPermalink}/${slide.uid}`

              function slidePositionDom(){
                return opening && slidePos === 3 ? "left"
                  : opening && slidePos === 5 ? "right"
                  : closing && slidePos === 3 ? "left"
                  : closing && slidePos === 5 ? "right"
                  : slidePos
              }

              function handleLink(e) {
                e.preventDefault()
                if (slidePos === 4 && !isDragging) {
                  setOpening(true)
                  sessionStorage.setItem("count", count)
                  setTimeout(function(){
                    navigate(permalink, {
                      state: {
                        fromHub: true,
                        hub: hub,
                        count: count,
                        prevSlide: orderedSlices.at(index === 0 ? slidesLength -1 : index - 1),
                        nextSlide: orderedSlices.at(index === slidesLength -1 ? 0 : index + 1)
                      }
                    })
                  }, 800)
                }
              }

              const isExpending = () => {
                return slidePos === 4 && opening ? true
                  : slidePos === 4 && closing ? true
                  : false 
              }

              const isOnScreen = () => {
                return slidePos !== 4 ? false
                  : opening ? false
                  : closing ? false
                  : true
              }

              const isSimpleSlider = (slide.type === "filter" || slide.type==="fooh")? true : false

              return (
                  <>
                    { !isSimpleSlider &&
                        <Link 
                          to={permalink} 
                          className={`${styles.slide} bg-${slide.data.page_color} ${isExpending() ? styles.slideOpening : ""} ${isDragging ? styles.disableAnim : ""} slide-anim`}
                          slide-position={ slidePositionDom() }
                          slide-index={index}
                          key={index}
                          onTouchStart={(e) => handleSlideTouchStart(e, slidePos)}
                          onTouchMove={(e) => handleSlideTouchMove(e, slidePos)} 
                          onTouchEnd={(e) => handleSlideTouchEnd(e, slidePos)}
                          onClick={(e) => {counter(slidePos); handleLink(e) }}
                          style={{
                            top: curSwipe === slidePos ? `calc(${pos}px + var(--slide-top))` : "var(--slide-top)",
                            transform: curSwipe === slidePos && pos < 0 ? `translate(-50%, 0) scale(${1 + (pos * 0.0005 )})` : "",
                            transformOrigin: curSwipe === slidePos && pos < 0 ? "center" : ""
                          }}
                        >  

                          {/* { (slidePos !== "hided" && slide.type === "creation") &&
                            <DuotoneImg container={styles.cover} img={slide.data.main_image.localFile} color={slide.data.page_color} />
                          } */}

                          { (slidePos !== "hided" && slide.type === "creation") &&
                            <GatsbyImage 
                              image={getImage(slide.data.main_image.localFile)} 
                              objectFit="COVER"
                              className={styles.cover}
                            />
                          }

                          {/* { (slidePos === 4 && slide.type === "creation") &&
                            <GatsbyImage 
                            image={getImage(slide.data.main_image.localFile)} 
                            objectFit="COVER"
                            className={`${styles.coverColored} ${isExpending() ? styles.coverColoredActive : ""}`}
                            />
                          } */}

                          { slide.type === "creation" &&
                            <div className={`${styles.projectTitle} bg-${slide.data.page_color} ${isOnScreen() ? styles.projectTitleActive : ""}`}>
                              <h2>{slide.data.client && slide.data.client.text}</h2>
                              { slide.data.title.text }, &nbsp;
                              {slide.data.date && (
                                    <>
                                      {slide.data.date.toString().substring(0,4)}
                                    </>
                                  )}
                            </div>
                          }

                          { slide.type === "news" &&
                              <BreakingNews color={slide.data.page_color} visible={isExpending() ? false : true} entrance={false} />
                          } 
                          
                          { slide.type === "news" &&
                            <div className={`${styles.newsTitle} ${isExpending() ? styles.newsTitleOpening : ""}`}>
                              <h2>{ slide.data.title.text }</h2>
                            </div>
                          }
                          
                          { (slide.data.date && slide.type === "news") && (
                              <div className={styles.newsDate}>
                                {slide.data.date.toString().substring(8,10)} / {slide.data.date.toString().substring(5,7)} / {slide.data.date.toString().substring(0,4)}
                              </div>
                          )}

                          {/* { (slidePos !== "hided" && slide.type === "page") &&
                            <DuotoneImg container={styles.cover} position={slide.data.main_image_position} img={slide.data.main_image.localFile} color={slide.data.page_color} doodle={slide.data.main_image_doodle !== undefined ? slide.data.main_image_doodle.url : null }/>
                          } */}
                          { (slidePos !== "hided" && slide.type === "page") &&
                            <GatsbyImage 
                              image={getImage(slide.data.main_image.localFile)} 
                              objectFit="COVER"
                              objectPosition={slide.data.main_image_position}
                              className={styles.cover}
                            />
                           
                          }
                          {slide.type === "page" &&
                            <div className={`${styles.pageTitle} ${isExpending() ? styles.pageTitleActive : ""} ${slide.data.page_color === "blue" ? styles.blue : ""}`}text={slide.data.home_title_style}>
                                <Doodle data={slide.data.home_title.richText} size="bold"  />
                            </div>                          
                          }

                      </Link>    
                    }

                    { isSimpleSlider &&
                      <div 
                      key={index}
                        className={`${styles.slide} bg-${slide.data.page_color} ${isExpending() ? styles.slideOpening : ""} slide-anim`}
                        slide-position={ slidePositionDom() }
                        slide-index={index}
                        onTouchStart={(e) => handleSlideTouchStart(e, slidePos)}
                        onTouchMove={(e) => handleSlideTouchMove(e, slidePos)} 
                        onTouchEnd={(e) => handleSlideTouchEnd(e, slidePos)}
                        onClick={(e) => {counter(slidePos) }}
                        style={{
                          top: curSwipe === slidePos ? `calc(${pos}px + var(--slide-top))` : "var(--slide-top)"
                        }}
                      >
                          {/* { slidePos !== "hided" &&
                            <>
                             <DuotoneImg container={styles.cover} img={slide.data.main_image.localFile} color={slide.data.page_color} />
                            
                            </>
                          } */}
                          { slidePos !== "hided" &&
                            <>
                              <GatsbyImage 
                                image={getImage(slide.data.main_image.localFile)} 
                                objectFit="COVER"
                                className={styles.cover}
                              />
                            </>
                          }
                          { (slidePos >= 3 && slidePos <= 5 && slide.data.video_url?.url?.length > 1) &&
                            <HubInlineVideo slide={slide} slidePos={slidePos} isDragging={isDragging} />
                          }
                          <div className={`${styles.projectTitle} bg-${slide.data.page_color} ${isOnScreen() ? styles.projectTitleActive : ""}`}>
                            {/* <h2>{ slide.data.title.text }</h2> */}
                            {slide.data.client && slide.data.client.text}, &nbsp;
                            {slide.data.date && (
                                  <>
                                    {slide.data.date.toString().substring(0,4)}
                                  </>
                                )}
                          </div>
                      </div>  
                    }
                  </>
              )
            })}
          </div>
    </Layout>
  )
}

export default Hub
