import React, {useState, useRef, useEffect} from "react"
import { Link } from "gatsby"
import * as styles from "../styles/tagsMenu.module.scss"

const TagsMenu = ({data, lang, moving}) => {
  const subMenuRef = useRef()
  const [isVisible, setIsVisible] = useState(false)

  const isTouchFn = () => {
    try {
      document.createEvent("TouchEvent");
      return true
    } catch (e){
      return false;
    }
  }
  const isTouch = isTouchFn()

  const handleClickOutside = e => {
    if (subMenuRef.current.contains(e.target)){
      return
    } 
    setIsVisible(false)
  }

  useEffect( () => {
      if(isVisible){
          if (isTouch){
            document.addEventListener("touchstart", handleClickOutside)
          } else { 
            document.addEventListener("mousedown", handleClickOutside)
          }
          
      } else {
          document.removeEventListener("mousedown", handleClickOutside)
          document.removeEventListener("touchstart", handleClickOutside)
      }
      return () => {
          document.removeEventListener("mousedown", handleClickOutside)
          document.removeEventListener("touchstart", handleClickOutside)
      }
     
  }, [isVisible])

  return (
   <div className={`${styles.container} ${isVisible ? styles.containerActive : ""} ${moving ? styles.moving : ""} tags-intro`} ref={subMenuRef}>
      <div className={`${styles.button} ${isVisible ? styles.hided : styles.visible} `} onClick={() => setIsVisible(!isVisible) }>
        {lang === "fr" ? "catégories" : "categories"}
      </div>
      <ul className={isVisible ? styles.visible : styles.hided} >
        <Link to={`/${lang}/creations`} activeClassName={`${styles.activeAll}`}>
          <li className={styles.button} >{lang === "fr" ? "tous" : "all"} </li>
        </Link>
        <Link to={lang === "fr" ? `/${lang}/creations/filtres` : `/${lang}/creations/filters`} activeClassName={styles.active}>
          <li className={styles.button} >{lang === "fr" ? "Galerie AR" : "AR Gallery"} </li>
        </Link>
        <Link to={lang === "fr" ? `/${lang}/creations/fooh` : `/${lang}/creations/fooh`} activeClassName={styles.active}>
          <li className={styles.button} >{lang === "fr" ? "FOOH" : "FOOH"} </li>
        </Link>
        { data.map( (tag, index) => {
          return (
            <Link to={`/${lang}/tags/${tag.uid}`} key={index} activeClassName={styles.active}>
              <li className={styles.button}>
                { tag.data.name.text }
              </li>
            </Link>
          )
        })} 
      </ul>
   </div>
  )
}

export default TagsMenu
